import React, { useEffect } from 'react'

import Layout from '@components/layout/rootLayout.js'
import Download from '@components/download'
import linker from '@src/common/linker'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Default =  ({ pageContext: { locale } }) => {
  useEffect(() => {
    trackCustomEvent({
      category: 'ecommerce',
      action: 'download_free'
    })

  }, [])

  linker.setUrl(
    'https://storage.googleapis.com/arcpanel-web.appspot.com/ARCPanel.zxp?GoogleAccessId=firebase-adminsdk-o0cmh%40arcpanel-web.iam.gserviceaccount.com&Expires=16730323200&Signature=j1mB%2BZTQ0fLwGtX7EbbQackrOccti5UPACp7Ub%2Bk%2FySbfBrWMjZWOxPCGkZxvrw2bVieVIItYMUBcExCRTPdHtbpA4d9HiQrDB0q6cjjxwRrigBk%2BJZWTrH0KWs0sL1LZy7NhCL%2FsR%2BXRVX%2BvKSmcneY7B0xw3PWbXxt%2BGndSm0oLCW2qkLTI%2B3yHBHPxzmQ1uRbdiEibHfMErF01tiyLgcMqQ4LKNZZEeYP%2BisMddN9tJMMsPfg0IIDjs6DKxQGrWKrvw3HGu00wsy6lUhFEI6WQO8FHCjgmmG2Q53NhOBti2mt4TNuNnwKO8u%2BZGYmNcQDSfSxo%2Fxigx5%2BIxTUXQ%3D%3D'
  )

  return (
    <Layout locale={locale}>
      <Download />
    </Layout>
  )
}


export default Default;